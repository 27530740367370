/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Helmet } from 'react-helmet'
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'ekko-lightbox/dist/ekko-lightbox.css'
import 'scss/layout.scss'

// import logoUrl from '../images/logo.svg'
// import logoText from '../images/logo-text.svg'
// import Address from "../components/address"
// import Phone from "../components/phone"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Arizonia" />
        </Helmet>
        <div className="container">
          <Header siteTitle={data.site.siteMetadata.title} />
        </div>
        <main>{children}</main>
        <Footer author={data.site.siteMetadata.author} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
