import React from "react"
import PropTypes from "prop-types"

const colors = [
  'footer-red',
  'footer-blue',
  'footer-green',
  'footer-yellow',
  'footer-orange',
  'footer-red',
]

const Footer = ({author}) => (
  <footer className="mt-3 text-center">
    <div className="container-fluid">
      {colors.map((v, idx) => (
        <div key={`footer-row-${idx}`} className={`row ${v}`}>
          <div className="col-12">
            {idx === 2 ? (<>&copy; {new Date().getFullYear()}, {author}</>) : (<>&nbsp;</>)}
            {idx === 3 ? (<><a href="#top">Back to top</a></>) : (<>&nbsp;</>)}
          </div>
        </div>
      ))}
    </div>
    {/* <p>
      &copy; {new Date().getFullYear()}, {author}
    </p>
    <p>
      <a href="#top">Back to top</a>
    </p> */}
  </footer>
)

Footer.propTypes = {
  author: PropTypes.string,
}

Footer.defaultProps = {
  author: ``,
}

export default Footer
