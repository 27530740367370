import Layout from "../../../../src/components/layout";
import SeoMeta from "../../../../src/components/seo";
import Form1 from "../../../../src/static/pdf/Identification-and-Emergency-Information-LIC700.pdf";
import Form2 from "../../../../src/static/pdf/Childs-Preadmission-Health-History-LIC702.pdf";
import Form3 from "../../../../src/static/pdf/Personal-Rights-LIC613A.pdf";
import Form4 from "../../../../src/static/pdf/Parents-Rights-LIC500.pdf";
import Form5 from "../../../../src/static/pdf/Consent-For-Emergency-Medical-Treatment-LIC627.pdf";
import Form6 from "../../../../src/static/pdf/Physicians-Report-LIC701.pdf";
import Form7 from "../../../../src/static/pdf/Allergy-Information-Sheet.pdf";
import Form8 from "../../../../src/static/pdf/Trial-Basis.pdf";
import Form9 from "../../../../src/static/pdf/Authorized-Pick-Up.pdf";
import * as React from 'react';
export default {
  Layout,
  SeoMeta,
  Form1,
  Form2,
  Form3,
  Form4,
  Form5,
  Form6,
  Form7,
  Form8,
  Form9,
  React
};