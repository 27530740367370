/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    const jQuery = require("jquery")
    const $ = jQuery
    window.jQuery = window.$ = jQuery
    require("bootstrap")
    require("ekko-lightbox")
    $(function() {
      $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault()
        $(this).ekkoLightbox()
      })
    })
  }
}
