exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-message-from-the-director-mdx": () => import("./../../../src/pages/a-message-from-the-director.mdx" /* webpackChunkName: "component---src-pages-a-message-from-the-director-mdx" */),
  "component---src-pages-a-typical-day-js": () => import("./../../../src/pages/a-typical-day.js" /* webpackChunkName: "component---src-pages-a-typical-day-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admissions-mdx": () => import("./../../../src/pages/admissions.mdx" /* webpackChunkName: "component---src-pages-admissions-mdx" */),
  "component---src-pages-campus-js": () => import("./../../../src/pages/campus.js" /* webpackChunkName: "component---src-pages-campus-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cuisine-mdx": () => import("./../../../src/pages/cuisine.mdx" /* webpackChunkName: "component---src-pages-cuisine-mdx" */),
  "component---src-pages-diag-js": () => import("./../../../src/pages/diag.js" /* webpackChunkName: "component---src-pages-diag-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

