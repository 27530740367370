import React from "react"
import { Helmet } from 'react-helmet'

import { useStaticQuery, graphql, Link } from 'gatsby'
import logo from "../images/logo.png"

const Logo = () => {
  // const isDesktop = useMediaQuery({ query: '(min-width: 990px)' })
  return (
    <Link to="/" className="header-logo text-dark">
      <img src={logo} alt="Valley School since 1950. Building the leaders of tomorrow." className="logo img-fluid" />
    </Link>
  )
}

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      allHeaderLinksYaml {
        edges {
          node {
            href
            text
          }
        }
      }
    }
  `)
  const links = []
  const {edges} = data.allHeaderLinksYaml
  const chunk = Math.round(edges.length * 0.5)
  for (let i = 0; i < edges.length; i += chunk) {
    let group = edges.slice(i, i+chunk).map((edge, index) => {
      return <Link key={index} to={edge.node.href} className="p-2 text-muted">{edge.node.text}</Link>
    })
    links.push(group)
  }
  return (
    <header className="py-3">
      <Helmet>
        <style type="text/css">{`
          .logo {
            max-width: 200px;
          }
        `}</style>
      </Helmet>
      <div className="row d-sm-none">
          <div className="text-center col-12 d-block d-sm-none">
            <Logo />
          </div>
      </div>
      <div className="row align-items-center justify-content-between flex-nowrap">
          <div className="pt-1 col-6 col-sm-4">
              <div className="nav-scroller py-1 mb-2">
                                  {/* nav d-flex justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between flex-column flex-lg-row flex-sm-column flex-md-column */}
                  <nav className="nav d-flex justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between flex-column flex-sm-column flex-md-column flex-lg-row">
                    {links[0]}
                    {/* <Link to="/about-us" className="p-2 text-muted">About Us</Link>
                    <Link to="/a-typical-day" className="p-2 text-muted">A Typical Day</Link>
                    <Link to="/facility" className="p-2 text-muted">Facility</Link> */}
                  </nav>
              </div>
          </div>
          <div className="col-4 text-center d-sm-block d-none">
            <Logo />
          </div>
          <div className="pt-1 col-6 col-sm-4">
              <div className="nav-scroller py-1 mb-2">
                  <nav className="nav d-flex justify-content-lg-between flex-column justify-content-end justify-content-sm-end justify-content-md-end flex-sm-column flex-md-column align-items-end align-items-sm-end align-items-md-end flex-lg-row">
                    {links[1]}
                    {/* <Link to="/staff" className="p-2 text-muted">Staff</Link>
                    <Link to="/contact-us" className="p-2 text-muted">Contact Us</Link>
                    <Link to="/newsletter" className="p-2 text-muted">Newsletter</Link> */}
                  </nav>
              </div>
          </div>
      </div>
    </header>
  )
}

export default Header
